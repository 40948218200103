import './Libro.css';
import { BiLibrary } from "react-icons/bi";


export default function Libro(props) {
  return (
    <div className={`relative basis-6/12 md:basis-4/12 lg:basis-2/12 p-3 ${props.libro.letto ? "letto" : ""}`}>
      <div className='copertina rounded-md' onClick={()=>props.onLettura(props.libro)}>
        <img
          src={props.libro.copertina}
          alt={props.libro.titolo} />
        <p className='absolute right-1 bottom-1 px-1 text-xs text-white bg-black'>Pag. {props.libro.pagine}</p>
      </div>

      <p className={`cursor-pointer absolute top-5 right-5 text-white text-center p-2 text-xs rounded-md ${props.libro.libreria ? "bg-green-600" : "bg-red-600"}`} onClick={()=>props.onLibreria(props.libro)}><BiLibrary /></p>
      <p className='absolute top-5 left-5 bg-orange-600 text-white text-center p-2 text-xs rounded-md'>{props.libro.tipo}</p>
      
      
      <h3 className='font-bold text-lg text-white'>{props.libro.titolo}</h3>
      <p className='text-white'>{props.libro.anno_pubblicazione}</p> 

    </div>
  )
}