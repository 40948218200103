import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyD01jV171Kp9jjML1OPQPNwr-JFg-5awkc",
  authDomain: "kingbook-ba9da.firebaseapp.com",
  projectId: "kingbook-ba9da",
  storageBucket: "kingbook-ba9da.appspot.com",
  messagingSenderId: "615882317207",
  appId: "1:615882317207:web:2bced8b9fce52fc704b8d2"
}

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();