import React,{useState,useEffect,useCallback} from 'react';

import {db, auth} from "./firebase";

import Libro from './components/Libro'
import Header from './components/Header'

function App() {
  const [book,setBook]=useState([]);
  const [filteredBook,setFilteredBook]=useState([]);
  const [login,setLogin]=useState();
  const [anonimo,setAnonimo]=useState(false);
  const [filter,setFilter]=useState('all');
  const [search, setSearch]=useState();
  const [order, setOrder]=useState('anno_pubblicazione');
  const [menu, setMenu]=useState(false)

  /* async function getBook(db){
    db.collection("libri").orderBy(order, "asc").onSnapshot((snapshot) => {
      setBook(snapshot.docs.map((doc => {
        let data = doc.data()
        data.id = doc.id
        return data;
      })))
      setFilteredBook(snapshot.docs.map((doc => {
        let data = doc.data()
        data.id = doc.id
        return data;
      })));
    });
  } */

  const getBook = useCallback(async (db) => {
    db.collection("libri").orderBy(order, "asc").onSnapshot((snapshot) => {
      setBook(snapshot.docs.map((doc => {
        let data = doc.data()
        data.id = doc.id
        return data;
      })))
      setFilteredBook(snapshot.docs.map((doc => {
        let data = doc.data()
        data.id = doc.id
        return data;
      })));
    });
  }, [order])

  function loginFunc(event, auth){
    event.preventDefault();
    auth.signInWithEmailAndPassword(event.target.email.value, event.target.password.value)
    .then((userCredential) => {
      sessionStorage.setItem('Auth Token', userCredential.user.refreshToken)
      setLogin(userCredential.user.uid);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  function anonimoLoginFunc(auth){
    auth.signInAnonymously()
    .then((userCredential) => {
      sessionStorage.setItem('Auth Token', userCredential.user.refreshToken)
      setLogin(userCredential.user.uid);
      setAnonimo(true);
    })
    .catch((error) => {
      console.log(error);
    });
    
  }

  function handleLogout(){
    sessionStorage.removeItem('Auth Token')
    setLogin();
  }

  useEffect(()=>{
    setLogin(sessionStorage.getItem('Auth Token'));
    getBook(db)
  },[getBook])

  useEffect(()=>{
    getBook(db)
  },[order,getBook])

  useEffect(()=>{
    var tempFiltBook = [...book];
    if(search!=="" || search!==undefined){   
      setFilteredBook(tempFiltBook.filter(item=>{return item?.titolo?.toLowerCase().includes(search?.toLowerCase())}))
    }
  },[book, search])

  useEffect(()=>{
    var tempFiltBook = [...book];

    switch (filter) {
      case "read":
        setFilteredBook(tempFiltBook.filter(item=>item.letto))
        break;
      case "notread":
        setFilteredBook(tempFiltBook.filter(item=>!item.letto))
        break;
      case "presenti":
        setFilteredBook(tempFiltBook.filter(item=>item.libreria))
        break;
      case "mancanti":
        setFilteredBook(tempFiltBook.filter(item=>!item.libreria))
        break;
      default:
        setFilteredBook(tempFiltBook);
        break;
    }
  },[book, filter])

  function handleLettura(libro){
    if(!anonimo){
    db.collection("libri").doc(libro.id).update({letto: !libro.letto});
    }
  }

  function handleLibreria(libro){
    if(!anonimo){
    db.collection("libri").doc(libro.id).update({libreria: !libro.libreria});
    }
  }

  function handleSearch(event){
    setSearch(event.target.value);
  }

  return (
    <>
      <Header user={login} onLogout={handleLogout} onMenu={()=>setMenu(!menu)}></Header>
      {login ? (
      <>
        {
        (menu)?<div className='bg-zinc-400 py-4'>
        <div className='flex justify-center mb-4'>
          <div className='space-x-2 text-center'>
            <p className='mb-2'>Ordina per:</p>
            <button onClick={() => setOrder("anno_pubblicazione")} className={`py-2 px-4 rounded ${order==="anno_pubblicazione"?'bg-zinc-600 text-white':'bg-transparent border border-zinc-600'}`}>Pubblicazione</button>
            <button onClick={() => setOrder("titolo")} className={`py-2 px-4 rounded ${order==="titolo"?'bg-zinc-600 text-white':'bg-transparent border border-zinc-600'}`}>Titolo</button>
            <button onClick={() => setOrder("pagine")} className={`py-2 px-4 rounded ${order==="pagine"?'bg-zinc-600 text-white':'bg-transparent border border-zinc-600'}`}>Pagine</button>
          </div>
        </div>
        <div className='flex justify-center mb-4'>
          <div className='space-x-2 text-center'>
            <p className='mb-2'>Mostra {filteredBook.length} risultati:</p>
            <button onClick={() => setFilter("all")} className={`py-2 px-4 mb-2 rounded border border-zinc-600 ${filter==="all"?'bg-zinc-600 text-white':'bg-transparent'}`}>Tutti</button>
            <button onClick={() => setFilter("read")} className={`py-2 px-4 mb-2 rounded border border-zinc-600 ${filter==="read"?'bg-zinc-600 text-white':'bg-transparent'}`}>Letti</button>
            <button onClick={() => setFilter("notread")} className={`py-2 px-4 mb-2 rounded border border-zinc-600 ${filter==="notread"?'bg-zinc-600 text-white':'bg-transparent'}`}>Da leggere</button>
            <button onClick={() => setFilter("presenti")} className={`py-2 px-4 mb-2 rounded border border-zinc-600 ${filter==="presenti"?'bg-zinc-600 text-white':'bg-transparent'}`}>Presenti</button>
            <button onClick={() => setFilter("mancanti")} className={`py-2 px-4 mb-2 rounded border border-zinc-600 ${filter==="mancanti"?'bg-zinc-600 text-white':'bg-transparent'}`}>Mancanti</button>
          </div>
        </div>
        {
          (filter==="all")?
          <div className='m-4'>
            <input type="text" className='placeholder:text-gray-200 text-gray-200 border-2 border-zinc-600 rounded-md bg-zinc-500 w-full p-2' placeholder='Cerca...' onChange={handleSearch} />
          </div>:<></>
        }
        </div>
        :
        <></>
        }
        
        
        <div className='flex flex-row flex-wrap justify-left pt-2'>
          {filteredBook.map((item)=> <Libro key={item.id} libro={item} onLettura={handleLettura} onLibreria={handleLibreria}></Libro>)}
        </div>
      </>
      ) : (
      <div className='container md:w-1/2 mx-auto text-center py-4 px-5'>
        <h2 className="text-4xl font-bold text-white">Login</h2>
        <form onSubmit={e => loginFunc(e, auth)} className="mb-5">
          <input className="placeholder:text-gray-200 text-gray-200 border-zinc-800 rounded-md bg-zinc-500 p-2 block w-full my-5" type="email" name="email" placeholder='Email' />
          <input className="placeholder:text-gray-200 text-gray-200 border-zinc-800 rounded-md bg-zinc-500 p-2 block w-full my-5" type="password" name="password" placeholder='Password' />
          <input className="bg-zinc-900 text-white py-2 px-4 rounded" type="submit" value='Login' />
        </form>
        <p className='text-white mb-2'>Or</p>
        <button onClick={() => anonimoLoginFunc(auth)} className="bg-zinc-900 text-white py-2 px-4 rounded">Accesso visitatore</button>
      </div>
      )}
    </>
  );
}

export default App;
