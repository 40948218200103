import { BiLogOut, BiMenu } from "react-icons/bi";

export default function Header(props){
    return(
        <header className='relative bg-zinc-900 text-white text-center py-5 text-xl shadow-md'>
            {props.user&&(<span className="absolute top-6 left-5 cursor-pointer" onClick={props.onLogout}><BiLogOut /></span>)}
            KingBook
            {props.user&&(<span className="absolute top-6 right-5 cursor-pointer" onClick={props.onMenu}><BiMenu /></span>)}
        </header>
    )
}